import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import NewsList from '~2-components/NewsList/NewsList';
import NewsTile from '~2-components/NewsTile/NewsTile';
import { dummyNewsListingData } from '~data/dummyNewsListingData';
import { Link } from 'gatsby';

const NewsListEg = `import NewsList from '~2-components/NewsList/NewsList';

<NewsList
    title='' //Required
    urlSlug='' //Required
>
    // Children tiles go here.
</NewsList>
`;

const SgNewsList = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>News List</h1>
                    <p>Uses a <Link to="/styleguide/components/slider-container/">Slider Container</Link> but with custom styling.</p>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={NewsListEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <NewsList
                        title="Latest News"
                        urlSlug={`/news`}
                    >
                        {dummyNewsListingData.map((item, i) => {
                            return (
                                <NewsTile
                                    key={i}
                                    url={item.url}
                                    imgUrl={item.imgUrl}
                                    imgAlt={item.imgAlt}
                                    date={item.date}
                                    title={item.title}
                                />
                            );
                        })}
                    </NewsList>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgNewsList;
